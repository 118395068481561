import React from "react";
import styled from "styled-components";

class AuthError extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>There was an error </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Container>
      </React.Fragment>
    );
  }
}

export default AuthError;

const Container = styled.div`
  width: 100%;
  padding: 0 15px 3rem 15px !important;
  margin: 0 auto;
  max-width: 1140px;
  color: #525f7f;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 450;

  @media (max-width: 1199px) {
    max-width: 960px;
  }
  @media (max-width: 991px) {
    max-width: 720px;
  }
  @media (max-width: 767px) {
    max-width: 540px;
  }
`;

const Card = styled.div`
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
`;

const CardHeader = styled.div`
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
const CardTitle = styled.div`
  margin-bottom: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.25rem;
`;
const Col = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 15px;
  margin: 0 auto;
  @media (min-width: 768px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
`;
