import React, { useEffect, Suspense } from 'react'
import styled from 'styled-components'
// import Admin from 'components/admin/Admin'
import { AuthGateway } from 'gateways/AuthGateway'
import ScrollToTop from 'context/ScrollToTop'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
// import Auth from 'components/auth/Auth'
import AuthErrorWrapper from 'gateways/AuthErrorGateway'
import WarningAlert from 'components/templates/WarningAlert'
import { useAppContext } from 'context/AppContext'
import { createBrowserHistory } from 'history'
import { gaKey } from 'configs'
import ReactGA from 'react-ga'
import { SpinLoaderFullScreen } from 'components/Loader/SpinLoaderFullScreen'
// import PublicRoutes from 'components/public/PublicRoutes'
import { Helmet } from 'react-helmet'
import { appEnvironment } from 'configs'

const Admin = React.lazy(() => import('./components/admin/Admin'))
const PublicRoutes = React.lazy(() => import('./components/public/PublicRoutes'))
const Auth = React.lazy(() => import('./components/auth/Auth'))

const hist = createBrowserHistory()

function sendPageView(location) {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
}

function GAListener({ children, trackingId, history }) {
  useEffect(() => {
    ReactGA.initialize(trackingId)
    sendPageView(history.location)
    return history.listen(sendPageView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, trackingId])

  return children
}

const Routes = () => {
  const [context, dispatch] = useAppContext()

  const closeAlertText = () => {
    dispatch({
      type: 'setAlertText',
      alertText: [],
    })
  }

  const onScreenWidthChange = () => {
    if (window.innerWidth < 975) {
      dispatch({
        type: 'setMobile',
        isMobile: true,
      })
    }

    if (window.innerWidth > 975) {
      dispatch({
        type: 'setMobile',
        isMobile: false,
      })
    }
  }

  // CHECK TO SEE IF APP BEING VIEWED ON MOBILE DEVICE
  useEffect(() => {
    onScreenWidthChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ADDING EVENT LISTENER FOR IF SCREEN WIDTH GETS DECREASED TO MOBILE
  useEffect(() => {
    window.addEventListener('resize', onScreenWidthChange)
    return () => {
      window.removeEventListener('resize', onScreenWidthChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      onClick={() => {
        context.alertText?.length && closeAlertText()
      }}
    >
      {appEnvironment === 'development' && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      {context.alertText && context.alertText.length > 0 && <WarningAlert id="warning-alert" />}
      <Router history={hist}>
        <GAListener trackingId={gaKey} history={hist}>
          <ScrollToTop />
          <Switch>
            <Route
              path="/auth"
              render={(props) => (
                <AuthErrorWrapper>
                  <Suspense fallback={<SpinLoaderFullScreen />}>
                    <Auth {...props} />
                  </Suspense>
                </AuthErrorWrapper>
              )}
            />
            <Route
              path="/admin"
              render={(props) => (
                <AuthGateway>
                  <Suspense fallback={<SpinLoaderFullScreen />}>
                    <Admin {...props} />
                  </Suspense>
                </AuthGateway>
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <Suspense fallback={<SpinLoaderFullScreen />}>
                  <PublicRoutes {...props} />
                </Suspense>
              )}
            />
            {/* {appEnvironment === 'production' ? (
              <Route
                path="/"
                render={(props) => (
                  <Suspense fallback={<SpinLoaderFullScreen />}>
                    <PublicRoutes {...props} />
                  </Suspense>
                )}
              />
            ) : (
              <Route
                path="/"
                render={(props) => (
                  <AuthGateway>
                    <Suspense fallback={<SpinLoaderFullScreen />}>
                      <PublicRoutes {...props} />
                    </Suspense>
                  </AuthGateway>
                )}
              />
            )} */}
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </GAListener>
      </Router>
    </Container>
  )
}

// Initialize google analytics page view tracking
hist.listen((location) => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

//const trackingId = "UA-1234567890-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(gaKey)

export default Routes

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  overflow: visible;
  background: var(--color-primary);
`
