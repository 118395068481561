import React, { useContext, useReducer } from 'react'
import Cookies from 'js-cookie'
import { appEnvironment } from 'configs'

export const AppContext = React.createContext()

export const getLoginIfCustom = () => {
  let matches = window.location.hostname.match(
    /([^.]+)[.](bookfocal\.localhost|dev\.bookfocal\.com|bookfocal\.com)/,
  )

  if (matches && matches[1] !== 'app') {
    return matches[1]
  }
}

export const getDomainIfCustom = () => {
  let matches = window.location.hostname.match(
    /([^.]+[.])(bookfocal\.localhost|dev\.bookfocal\.com|bookfocal\.com)/,
  )

  if (!matches && window.location.hostname !== 'localhost') {
    return window.location.hostname
  }
}

export const AuthState = () => {
  const token = getToken()
  const user = getUser()
  let isAuthed = false
  if (token !== '') {
    isAuthed = true
  }
  return {
    isAuthed: isAuthed,
    token: token,
    user: user,
    isMobile: false,
    scrolledToBottom: false,
    photographer: {},
    portfolios: [],
    packages: [],
    alertText: [],
    alertType: 'error',
    portfolioSave: false,
    calendarIsAuthed: false,
    name: '',
    stripePlans: [],
  }
}

export const AuthProvider = ({ reducer, initialState, children }) => (
  <AppContext.Provider value={useReducer(reducer, initialState)}>{children}</AppContext.Provider>
)

export const useAppContext = () => useContext(AppContext)

export const reducer = (state, action) => {
  if (action.type === 'setAuth') {
    setToken(action.token)
    return {
      ...state,
      isAuthed: action.isAuthed,
      token: action.token,
    }
  } else if (action.type === 'setUser') {
    setUser(action.user)
    return {
      ...state,
      user: action.user,
    }
  } else if (action.type === 'logout') {
    deleteToken()
    deleteUser()
    return {}
  } else if (action.type === 'setPhotographer') {
    return {
      ...state,
      photographer: action.photographer,
    }
  } else if (action.type === 'setPortfolios') {
    return {
      ...state,
      portfolios: action.portfolios,
    }
  } else if (action.type === 'setPackages') {
    return {
      ...state,
      packages: action.packages,
    }
  } else if (action.type === 'setMobile') {
    return {
      ...state,
      isMobile: action.isMobile,
    }
  } else if (action.type === 'setScrolled') {
    return {
      ...state,
      scrolledToBottom: action.scrolledToBottom,
    }
  } else if (action.type === 'setAlertText') {
    return {
      ...state,
      alertText: action.alertText,
    }
  } else if (action.type === 'setAlertType') {
    return {
      ...state,
      alertType: action.alertType,
    }
  } else if (action.type === 'setPortfolioSave') {
    return {
      ...state,
      portfolioSave: action.portfolioSave,
    }
  } else if (action.type === 'setCalendarIsAuthed') {
    return {
      ...state,
      calendarIsAuthed: action.calendarIsAuthed,
    }
  } else if (action.type === 'setName') {
    return {
      ...state,
      name: action.name,
    }
  } else if (action.type === 'setStripePlans') {
    return {
      ...state,
      stripePlans: action.stripePlans,
    }
  } else {
    throw new Error('invalid type: ' + action.type)
  }
}

let top_level_domain = window.location.hostname.replace(/(.*?)\.(dev\.)?([^.]+\.[^.]+)$/, '.$2$3')

let token_cookie_suffix = top_level_domain.match(/\.dev\./) ? '_dev' : ''

const setToken = (token) => {
  if (appEnvironment === 'production' || appEnvironment === 'development') {
    Cookies.set('bf_token' + token_cookie_suffix, token, {
      expires: 2,
      secure: true,
      domain: top_level_domain,
    })
  } else {
    Cookies.set('bf_token' + token_cookie_suffix, token, { expires: 2, domain: top_level_domain })
  }
}

const setUser = (user) => {
  let userString = JSON.stringify(user)

  if (appEnvironment === 'production' || appEnvironment === 'development') {
    Cookies.set('bf_user' + token_cookie_suffix, userString, {
      expires: 2,
      secure: true,
      domain: top_level_domain,
    })
  } else {
    Cookies.set('bf_user' + token_cookie_suffix, userString, { expires: 2, domain: top_level_domain })
  }
}

const getToken = () => {
  if (Cookies.get('bf_token' + token_cookie_suffix)) {
    return Cookies.get('bf_token' + token_cookie_suffix)
  } else {
    return ''
  }
}

export const getUser = () => {
  if (Cookies.get('bf_user' + token_cookie_suffix)) {
    let user = Cookies.get('bf_user' + token_cookie_suffix)
    return JSON.parse(user)
  } else {
    return {}
  }
}

const deleteToken = () => {
  if (Cookies.get('bf_token' + token_cookie_suffix)) {
    Cookies.remove('bf_token' + token_cookie_suffix, { domain: top_level_domain })
  }
}

const deleteUser = () => {
  if (Cookies.get('bf_user' + token_cookie_suffix)) {
    Cookies.remove('bf_user' + token_cookie_suffix, { domain: top_level_domain })
  }
}
