import React from 'react'
import { IoIosClose } from 'react-icons/io'
import styled, { keyframes } from 'styled-components'

import { useAppContext } from 'context/AppContext'

const WarningAlert = () => {
  const [context] = useAppContext()

  return (
    <Modal alertType={context.alertType} id="warning-alert" data-testid="warning-alert">
      <ExitIcon id="warning-alert-exit" />
      {context.alertText.map((message, i) => (
        <H1 key={message + i}>{message}</H1>
      ))}
    </Modal>
  )
}
export default WarningAlert

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

const Modal = styled.div`
  position: fixed;
  display: flex;
  background: ${(props) => (props.alertType === 'success' ? 'var(--success)' : 'var(--error)')};
  top: 10%;
  left: 50%;
  width: max-content;
  max-width: 90vw;
  padding: 1em 2.5em;
  transform: translate(-50%, 0);
  z-index: 99999;
  border-radius: none;
  flex-direction: column;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
  font-size: 16px;
  animation: 0.2s ${fadeIn} ease-out;
  @media (max-width: 550px) {
    font-size: 15px;
    max-width: 95vw;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`

const H1 = styled.h3`
  position: relative;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: white;
  letter-spacing: 1px;
  word-break: break-word;
`

const ExitIcon = styled(IoIosClose)`
  position: absolute;
  color: white;
  top: 0.2em;
  right: 0.2em;
  font-size: 1.6em;
  font-weight: 600;
  cursor: pointer;
  border: none;
  margin: 0 0 0 0.8em;
  padding: 0;
  :hover {
    opacity: 0.5;
  }
`
