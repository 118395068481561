import React from "react";
import AuthError from "components/errorNotice/AuthError";

class AuthErrorWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <AuthError />;
    } else {
      return this.props.children;
    }
  }
}

export default AuthErrorWrapper;
