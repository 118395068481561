import { useAppContext } from 'context/AppContext'
import { Redirect } from 'react-router'
import React from 'react'

export function AuthGateway(props) {
  const [auth] = useAppContext()
  if (auth.isAuthed) {
    return props.children
  } else {
    localStorage.setItem('redirectedFrom', window.location.href.split(window.location.host)[1])

    return (
      <Redirect
        from="/"
        to={{
          pathname: '/auth/login',
          // state: {
          //   redirectedFrom: window.location.href.split(window.location.host)[1],
          // },
        }}
      />
    )
  }
}
