import Routes from 'Routes'
import { AuthProvider, AuthState, reducer } from 'context/AppContext'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import { appEnvironment, pixelKey } from 'configs'
// import DevBanner from './components/general/DevBanner'

const App = () => (
  <Container>
    {/* <DevBanner /> */}
    <GlobalStyles />
    <Helmet>
      <title>Focal</title>
      <meta name="description" content="Marketplace for photographers" />
      <script>
        {appEnvironment === 'production'
          ? `!(function (f, b, e, v, n, t, s) {
          if (f.fbq) return
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          }
          if (!f._fbq) f._fbq = n
          n.push = n
          n.loaded = !0
          n.version = '2.0'
          n.queue = []
          t = b.createElement(e)
          t.async = !0
          t.src = v
          s = b.getElementsByTagName(e)[0]
          s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
        fbq('init', '${pixelKey}')
        fbq('track', 'PageView')`
          : null}
      </script>
      <noscript>
        {appEnvironment === 'production'
          ? `<img
            height="1"
            width="1"
            style="display: none"
            src=https://www.facebook.com/tr?id=${pixelKey}&ev=PageView&noscript=1
          />`
          : null}
      </noscript>
    </Helmet>
    <AuthProvider initialState={AuthState()} reducer={reducer}>
      <Routes />
    </AuthProvider>
  </Container>
)

export default App

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

const GlobalStyles = createGlobalStyle`
  html {
  --forward: #f4b436;
  --black: #222022;
  --success: #53A76B;
  --modal-yellow: #FFFBF5;
  --grey:#8C94A3;
  --light-grey:#EBECF0;
  --input-grey:#979797;
  --input-border:rgba(0, 0, 0, 0.3);
  --input-black:#191919;
  --background-grey:#fafafa;
  --error:#EC6A34;

  --nav-white:#F4F3F2;
  
  //--Flow Template2--//
  --white:#faf9f5;
  --textColor:#333333;

  --form-grey:#dee2e6;
  --dark-grey-1:#4d4b4b;
  --dark-grey-2:#626161;
  --light-grey-1:#a09f9f;
  --dark-yellow:#f1a50d;
  --light-yellow:#fff2d9;

  --silver-sand:#c1c1c1;
  }
`

Container.displayName = 'AppRoot'
