import React from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

export function SpinLoaderFullScreen(props) {
  return (
    <Container>
      <ClipLoader size={props.size ? props.size : 60} loading color="primary" />
      <br />
      {props.message && <H4>{props.message}</H4>}
    </Container>
  )
}

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const H4 = styled.h4`
  font-size: 0.9375rem;
  font-weight: 600;
  color: #172b4d !important;
  text-align: center !important;
`
