export const appEnvironment = process.env.REACT_APP_ENV

// To Do add environment variables for production... This is a testing key
export const stripeKey =
  appEnvironment === 'production' ? process.env.REACT_APP_STRIPE_KEY : `pk_test_7nVoSpgevPEz61f6RD894AFW`

export const googlePlacesKey =
  appEnvironment === 'production'
    ? process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PROD
    : 'AIzaSyA_DnuQ4SxJm-zgF0_L6-_xM1i_de9oKyQ'

export const googleCalendarKey =
  appEnvironment === 'production'
    ? process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY_PROD
    : 'AIzaSyBqvcr6EJsfK7RpJLgp86aScGXgtI8d8rQ'

export const CalendarOAuthID =
  appEnvironment === 'production'
    ? '225993599841-8eo9pcqu5o9680as62en6o7id3fl3qj3.apps.googleusercontent.com'
    : '366823960921-c1t2ffmfh364d5g88fgn4ihl4mkfhvhn.apps.googleusercontent.com'

// export const hotjarID =
//   appEnvironment === 'production' ? process.env.REACT_APP_HOTJAR_KEY : `2500340`

export const gaKey =
  appEnvironment === 'production' ? process.env.REACT_APP_ENV_GOOGLE_ANALYTICS_ID : `UA-127317331-4`

export const pixelKey =
  appEnvironment === 'production' ? process.env.REACT_APP_FB_PIXEL_ID : '3159645580747340'
