import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import './default.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { appEnvironment } from 'configs'

Sentry.init({
  dsn: 'https://f486993b799b427a8724c63519698e0d@o1187825.ingest.sentry.io/6307708',
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']

      //Overwrite to only log console.error
      levels: ['error'],
    }),
  ],
  environment: appEnvironment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.001,
})

ReactDOM.render(<App />, document.getElementById('root'))
